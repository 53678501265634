<template>
    <div class="mt-3">
        <v-sheet class="dense-inputs">
            <v-row no-gutters dense>
                <v-col cols="4" lg="4" md="6" xs="12" class="d-flex flex-row float-right">
                    <v-text-field
                        :label="$t('message.filterResults')"
                        :value="searchTerm"
                        autocomplete="off"
                        class="mt-2 mb-0 force-text-left"
                        prepend-inner-icon="mdi-filter-outline"
                        @change="searchTerm = $event"
                    ></v-text-field>
                    <v-btn class="ml-3" :loading="loading.filterResults" @click="filterResults">{{ $t('message.filter') }}</v-btn>
                    <v-btn class="ml-3" @click="searchTerm = null"><v-icon>refresh</v-icon></v-btn>
                    <ExportTableSalesCommission
                        :export-conditions="exportConditions"
                        :export-data="intakeItems"
                        :export-fields="headers"
                        :export-name="'intake'"
                        class="ml-3"
                    ></ExportTableSalesCommission>
                </v-col>
<!--                <v-col cols="8" lg="8" md="6" xs="12" v-if="false">-->
<!--                    <v-menu offset-y>-->
<!--                        <template v-slot:activator="{ on: menu }">-->
<!--                            <v-tooltip bottom>-->
<!--                                <template v-slot:activator="{ on: tooltip }">-->
<!--                                    <v-btn-->
<!--                                        class="ml-3"-->
<!--                                        v-on="{...tooltip, ...menu}">-->
<!--                                        <v-icon small dark v-on>ti ti-export</v-icon>-->
<!--                                    </v-btn>-->
<!--                                </template>-->
<!--                                <span>{{ $t('message.export') }}</span>-->
<!--                            </v-tooltip>-->
<!--                        </template>-->
<!--                        <ExportTableSalesCommission-->
<!--                            :export-data="intakeItems"-->
<!--                            :export-fields="exportFileFields"-->
<!--                            :export-name="'intake'"-->
<!--                            class="mr-2"-->
<!--                        ></ExportTableSalesCommission>-->
<!--                        <v-list>-->
<!--                            <v-list-item>-->
<!--                                <download-excel-->
<!--                                    :data="intakeItems"-->
<!--                                    :fields="exportFileFields"-->
<!--                                    :name="exportFileName"-->
<!--                                >-->
<!--                                    <v-list-item-title>{{ $t('message.exportAsExcel') }}</v-list-item-title>-->
<!--                                </download-excel>-->
<!--                            </v-list-item>-->
<!--                        </v-list>-->
<!--                    </v-menu>-->
<!--                </v-col>-->
            </v-row>
        </v-sheet>
        <v-overlay
            :value="loadingItems"
            absolute
            opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-overlay>
        <v-data-table
            :footer-props="{
                itemsPerPageOptions: [20,50,75,-1],
                showCurrentPage: true,
                showFirstLastPage: true
            }"
            :headers="headers"
            :items="intakeItems"
            :search="searchTerm"
            :options.sync="tableOptions"
            calculate-widths
            class="pt-0 pl-0 appic-table-light specification-table col-xs-12 col-md-8 col-lg-8"
            dense
            id="intakeTable"
            item-key="index"
            ref="commissionIntakeList"
            sort-by="index"
        >
            <template v-slot:body.prepend>
                <tr>
                    <th role="columnheader" class="text-start light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left"></th>
                    <th role="columnheader" class="text-start light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left"></th>
                    <th role="columnheader" class="text-start light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left"></th>
                    <th role="columnheader" class="text-start light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left"></th>
                    <th role="columnheader" class="text-start light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left">{{ $t('message.totals') }}</th>
                    <th role="columnheader" class="text-start light-green lighten-3 py-0 pr-0 pl-1 font-sm" style="text-align: right !important;">{{ formatThisNumber(totals.value,'0,0.00') }}</th>
                    <th role="columnheader" class="text-start light-green lighten-3 py-0 pr-0 pl-1 font-sm" style="text-align: right !important;">{{ formatThisNumber(totals.cost,'0,0.00') }}</th>
                    <th role="columnheader" class="text-start light-green lighten-3 py-0 pr-0 pl-1 font-sm" style="text-align: right !important;">{{ formatThisNumber(totals.margin,'0,0.00') }}</th>
                    <th role="columnheader" class="text-start light-green lighten-3 py-0 pr-0 pl-1 font-sm" style="text-align: right !important;">{{ formatThisNumber(totals.marginPct,'0,0.0') + '%' }}</th>
                    <th role="columnheader" class="text-start light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left"></th>
                </tr>
            </template>
            <template v-slot:item.index="{ item }">
                <v-menu>
                    <template v-slot:activator="{ on: menu }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on: tooltip }">
                                <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                    <v-icon>more_vert</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('message.moreActions') }}</span>
                        </v-tooltip>
                    </template>
                    <v-list dense>
                        <v-list-item class="font-sm" @click="openLink(item.Intake.link)"><v-icon color="black darken-1" small class="mr-1">fas fa-binoculars</v-icon>{{ $t('message.viewSalesDocument') }}</v-list-item>
                        <v-list-item class="font-sm" @click="openLink(item.Intake.poLink)" v-if="item.Intake.poLink != null"><v-icon color="black darkenn-1" small class="mr-1">fas fa-binoculars</v-icon>{{ $t('message.viewPurchaseOrder') }}</v-list-item>
                        <v-list-item class="font-sm" @click="openLink(item.Intake.accountLink)" v-if="item.Intake.accountLink != null"><v-icon color="black darken-1" small class="mr-1">fas fa-binoculars</v-icon>{{ $t('message.viewGrossMargin') }}</v-list-item>
                    </v-list>
                </v-menu>
            </template>
            <template v-slot:item.Intake.intakeDate="{ item }">
                {{ item.Intake.intakeDate != '0000-00-00' ? formatDate(item.Intake.intakeDate) : '' }}
            </template>
            <template v-slot:item.Intake.documentNo="{ item }">
                <span class="font-weight-bold">{{ item.Intake.documentNo }}</span>
            </template>
            <template v-slot:item.Intake.value="{item}">
                <div class="text-no-wrap text-right" v-if="item.Intake.value != null">
                    {{ formatThisNumber(item.Intake.value, '0,0.00' ) }}
                </div>
            </template>
            <template v-slot:item.Intake.cost="{item}">
                <div class="text-no-wrap text-right" v-if="item.Intake.cost != null">
                    {{ formatThisNumber(item.Intake.cost, '0,0.00' ) }}
                </div>
            </template>
            <template v-slot:item.Intake.margin="{item}">
                <div class="text-no-wrap text-right" v-if="item.Intake.margin != null">
                    {{ formatThisNumber(item.Intake.margin, '0,0.00' ) }}
                </div>
            </template>
            <template v-slot:item.Intake.marginPct="{item}">
                <div class="text-no-wrap text-right">
                    {{ item.Intake.marginPct }}
                </div>
            </template>
            <template v-slot:item.Intake.notes="{item}">
                <div class="d-flex flex-column">
                    <span v-for="note in item.Intake.notes">{{ note }}</span>
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import {formatDate, numberFormat} from "Helpers/helpers";
import {mapFields} from "vuex-map-fields";

const ExportTableSalesCommission = () => import("Components/Appic/ExportTableSalesCommission");

    export default {
        name: "CommissionIntakeList",
        components: {ExportTableSalesCommission},
        props: ['loadingItems','intakeItems'],
        data() {
            return {
                exportParams: [],
                loading: {
                    filterResults: false
                },
                searchTerm: null,
                tableOptions: {
                    page: 1
                },
                totals: {
                    cost: 0,
                    margin: 0,
                    marginPct: 0,
                    value: 0
                }
            }
        },
        computed: {
            ...mapFields('commission',{
                exportConditions: 'exportConditions'
            }),
            ...mapFields('runtime',{
                openedTabs: 'openedTabs'
            }),
            exportFileName() {
                return 'intake-list.xls'
            },
            exportFileFields() {
                let fields = {}
                this.headers.forEach(header => {
                    if(header.export) {
                        fields[header.text] = header.value
                    }
                })
                return fields
            },
            formatDate: () => formatDate,
            headers() {
                let headers = [
                    {
                        id: 0,
                        text: this.$t('message.actions'),
                        value: 'index',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct',
                        searchable: false,
                        sortable: false,
                        export: false
                    },
                    {
                        id: 1,
                        text: this.$t('message.monthYear'),
                        value: 'Intake.intakeMonthString',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: true,
                        sortable: false,
                        export: true
                    },
                    {
                        id: 2,
                        text: this.$t('message.salesColleague'),
                        value: 'Intake.salesContact',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: true,
                        sortable: false,
                        export: true
                    },
                    {
                        id: 3,
                        text: this.$t('message.document'),
                        value: 'Intake.documentNo',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: true,
                        sortable: false,
                        export: true
                    },
                    {
                        id: 4,
                        text: this.$t('message.intakeDate'),
                        value: 'Intake.intakeDate',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: true,
                        sortable: false,
                        export: true
                    },
                    {
                        id: 5,
                        text: this.$t('message.intakeValue'),
                        value: 'Intake.value',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: true,
                        sortable: false,
                        export: true
                    },
                    {
                        id: 6,
                        text: this.$t('message.intakeCost'),
                        value: 'Intake.cost',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: true,
                        sortable: false,
                        export: true
                    },
                    {
                        id: 7,
                        text: this.$t('message.intakeMargin'),
                        value: 'Intake.margin',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: true,
                        sortable: false,
                        export: true
                    },
                    {
                        id: 8,
                        text: this.$t('message.intakeMarginPct'),
                        value: 'Intake.marginPct',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: true,
                        sortable: false,
                        export: true
                    },
                    {
                        id: 9,
                        text: this.$t('message.notes'),
                        value: 'Intake.notes',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: true,
                        sortable: false,
                        export: true
                    },
                ]
                return headers
            }
        },
        methods: {
            computeTotals() {
                let _this = this
                this.$nextTick().then(function(){

                    _this.totals.cost = 0
                    _this.totals.value = 0
                    _this.totals.margin = 0
                    _this.totals.marginPct = 0

                    let items = _this.$refs.commissionIntakeList.$children[0].filteredItems
                    items.forEach((item) => {
                        _this.totals.cost += item.Intake.cost
                        _this.totals.value += item.Intake.value
                        _this.totals.margin += item.Intake.margin
                    })
                    if(_this.totals.value > 0){
                        _this.totals.marginPct = (_this.totals.margin / _this.totals.value)*100
                    }
                })
            },
            // exportTableToExcel() {
            //     this.$toast.success(this.$t('message.successes.exportExcelPleaseWaitForDownload'),
            //         {
            //             timeout: 3000,
            //             color: 'info',
            //             classes: ['icon-float-left'],
            //             icon: 'check_circle_outline',
            //             x: 'centered',
            //             y: 'top'
            //         }
            //     )
            // },
            // exportTableToPdf() {
            //     this.$toast.success(this.$t('message.successes.exportPdfPleaseWaitForDownload'),
            //         {
            //             timeout: 3000,
            //             color: 'info',
            //             classes: ['icon-float-left'],
            //             icon: 'check_circle_outline',
            //             x: 'centered',
            //             y: 'top'
            //         }
            //     )
            // },
            filterResults () {
                this.loading.filterResults = true
                let _this = this
                setTimeout(function(){
                    _this.loading.filterResults = false
                },700)
            },
            formatThisNumber(value,format){
                return numberFormat(value,format)
            },
            openLink(link){
                let tab = window.open(link,'_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            }
        },
        watch: {
            searchTerm() {
                this.computeTotals()
            }
        },
        mounted(){
            this.computeTotals()
        }
    }
</script>

<style>
.font-xs {
    font-size: 0.65rem;
}
.mt-5px {
    margin-top: 5px !important;
}
.mt-6px {
    margin-top: 6px !important;
}
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.truncate {
    display: inline-block;
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}
</style>